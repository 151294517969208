import React from 'react';

import Layout, { LayoutTitle } from 'components/Layout';
import SeoHelmet from 'components/SeoHelmet';
import PhotosQuery from 'components/Queries/PhotosQuery';

function Portofolio() {
  return (
    <Layout>
      <SeoHelmet title="Portofoliu" />
      <LayoutTitle title="Echipament" />
      <PhotosQuery filter="echipamente" />
    </Layout>
  );
}

export default Portofolio;
